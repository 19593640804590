import React, { Component } from 'react';

class Donate extends Component {
    render() {
        return (
          <section className="hero is-large has-background-black">
          <div className="hero-body">
            <div className="container has-text-centered">
              <h1 className="title">
                COMING SOON
              </h1>
              <h2 className="subtitle">
                building a website is hard, guys...
              </h2>
            </div>
            </div>
          </section>
        )
    }
}
export default Donate;
