import jonoPhoto from '../../../images/aboutHeader.png'
import erinPhoto from '../../../images/aboutHeader.png'

const riders = [
  {
    id: 1,
    name: 'Jono Freeman',
    stravaLink: '26',
    totalMileage: '3200',
    img: jonoPhoto,
    bio: 'I am a practicing art therapist currently finishing my masters at Hofstra university. ETC ETC…'
  },
  {
    id: 2,
    name: 'Erin Poland',
    stravaLink: '28',
    totalMileage: '2400',
    img: erinPhoto,
    bio: 'I am a practicing art therapist currently finishing my masters at Hofstra university. ETC ETC…'
  }
];

export default riders;
